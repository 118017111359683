.goroku__container {
    @apply container
    mx-auto
    px-3
    md:my-20
    my-12;
}

.goroku__archiveLoopWrap {
    @apply lg:max-w-2xl
    mx-auto;
}

.goroku__homeLoopWrap {
    @apply grid
    md:grid-cols-3
    grid-cols-1
    gap-8;
}

.goroku__homeArchive__item {
    @apply border-b-2
    border-text
    mb-8
    pb-4;
}

.goroku__homeArchive__title {
    @apply text-xl
    py-4;
}

.goroku__homeArchive__date {
    @apply pt-4;
}

.goroku__homeArchive__thumbnail {
    @apply overflow-hidden;
}

.goroku__homeArchive__thumbnail img {
    aspect-ratio: 4 / 3;
    @apply w-full
    transition-transform
    object-cover;
}

.goroku__homeArchive__item a:hover img {
    @apply transform
    scale-110;
}

.goroku__archive__item {
    @apply mt-8
    mb-12
    pb-8
    border-b-2;
}

.goroku__header {
    @apply flex
    items-center
    mb-8;
}

.goroku__header__category {
    @apply pr-8;
}

.goroku__title {
    @apply md:text-5xl
    text-4xl
    font-bold
    mb-8
    tracking-wide
    leading-relaxed
    md:leading-relaxed;
}

.goroku__archive__inner {
    @apply grid
    md:grid-cols-2
    grid-cols-1
    gap-8;
}

.goroku__archive__inner-left {
    @apply order-2
    md:order-1;
}

.goroku__archive__inner-right {
    @apply order-1
    md:order-2;
}

.goroku__archive__item:nth-of-type(2n) .goroku__archive__inner-left {
    @apply order-2;
}
.goroku__archive__item:nth-of-type(2n) .goroku__archive__inner-right {
    @apply order-1;
}

.goroku__archive__item a:hover .goroku__thumbnail img {
    @apply transform
    scale-110;
}

.goroku__thumbnail {
    @apply overflow-hidden;
}

.goroku__thumbnail img {
    aspect-ratio: 4 / 3;
    @apply transition-transform
    object-cover
    w-full;
}

.goroku__archive__excerpt {
    @apply mb-8;
}

.goroku__single {
    @apply container
    mx-auto
    lg:max-w-4xl
    px-3;
}

.goroku__single__content {
    @apply whitespace-pre-line;
}

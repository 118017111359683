.newsPage .hero-child__image {
    object-position: bottom;
}

.news__container {
    @apply container
    mx-auto
    px-3;
}

.news__loopWrap {
    @apply grid
    lg:grid-cols-3
    md:grid-cols-2
    grid-cols-1
    gap-8
    mb-8;
}

.news__archive__item {
    @apply relative
    mb-20
    pb-4;
}

.news__archive__inner {
    @apply border-b-2
    border-text;
}

.news__archive__title {
    @apply text-xl
    pt-4
    pb-8;
}

.news__archive__date {
    @apply pt-4;
}

.news__archive__thumbnail {
    @apply overflow-hidden;
}

.news__archive__thumbnail img {
    aspect-ratio: 4 / 3;
    @apply w-full
    transition-transform
    object-cover;
}

.news__archive__item a:hover img {
    @apply transform
    scale-110;
}

.news__single {
    @apply container
    mx-auto
    px-3
    lg:px-12;
}

.news__single__title {
    @apply md:text-3xl
    text-xl
    font-bold
    md:tracking-wider
    tracking-wide
    border-b-3
    border-text
    pb-2
    mb-4;
}

.news__single__date {
    @apply text-right
    mb-8;
}

.news__single__content {
    @apply whitespace-pre-line;
}

.news__single__thumbnail img {
    aspect-ratio: 16 / 9;
    @apply w-full
    object-cover;
}

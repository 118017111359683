.staff__container {
    @apply container
    mx-auto
    px-3;
}

.staff__loopWrap {
    @apply grid
    lg:grid-cols-3
    md:grid-cols-2
    grid-cols-1
    gap-8
    mb-8;
}

.staff__loopWrap-veteran {
    @apply grid
    lg:grid-cols-11
    md:grid-cols-10
    grid-cols-1
    gap-12
    mb-8;
}

.staff__loopWrap-veteran li {
    @apply col-span-4;
}

.staff__description {
    @apply grid
    md:grid-cols-3
    grid-cols-1
    gap-8;
}

.staff__description__left img {
    @apply w-3/4
    mx-auto;
}

.staff__description__right {
    @apply col-span-2;
}

.staff__description__text {
    @apply whitespace-pre-line;
}

.staff__archive__item {
    @apply relative
    mb-20
    pb-4;
}

.staff__archive__inner {
    @apply border-b-2
    border-text;
}

.staff__archive__title {
    @apply text-xl
    pt-4
    pb-8;
}

.staff__archive__date {
    @apply pt-4;
}

.staff__archive__writer__wrap {
    @apply flex
    items-center
    justify-end
    absolute
    -bottom-16
    -right-4
    hover:opacity-100;
}

.staff__archive__writer__wrap:hover .staff__archive__icon {
    @apply transform
    scale-110;
}

.staff__archive__writer {
    @apply text-lg
    pr-4;
}

.staff__archive__icon {
    @apply w-28
    transition-transform;
}

.staff__archive__thumbnail {
    @apply overflow-hidden;
}

.staff__archive__thumbnail .wp-post-image {
    aspect-ratio: 4 / 3;
    @apply w-full
    transition-transform
    object-cover;
}

.staff__archive__item a:hover .wp-post-image {
    @apply transform
    scale-110;
}

.staff__single {
    @apply container
    mx-auto
    px-3
    lg:px-12;
}

.staff__single__title {
    @apply md:text-3xl
    text-xl
    font-bold
    md:tracking-wider
    tracking-wide
    border-b-3
    border-text
    pb-2
    mb-4;
}

.staff__single__date {
    @apply text-right
    mb-8;
}

.staff__single__content {
    @apply whitespace-pre-line;
}

.staff__single__thumbnail img {
    aspect-ratio: 16 / 6;
    @apply w-full
    object-cover;
}

.staff__writer {
    @apply grid xl:grid-cols-3
    md:grid-cols-2
    grid-cols-1
    gap-8;
}

.staff__writer__item {
    @apply text-center
    mb-8;
}

.staff__writer__item a:hover img {
    @apply transform
    scale-105;
}

.staff__writer__item__icon {
    @apply w-80
    mx-auto
    mb-8
    transition-transform;
}

.staff__writer__item__inner {
    @apply grid
    grid-cols-2;
}

.staff__writer__number {
    @apply text-7xl
    text-primary
    font-bold
    border-r
    border-b
    px-10
    py-6
    inline-block;
}

.staff__writer__description {
    @apply text-left
    leading-relaxed
    font-bold;
}

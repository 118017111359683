@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700&display=swap"); */

html {
    @apply !mt-0;
}

body {
    /* background-color: #e5f331; */
    background-color: #f6f578;
    /* font-family: "Zen Kaku Gothic New", sans-serif; */
    font-family: "Noto Sans JP";
    letter-spacing: 0.1rem;
    line-height: 1.8;
    color: #212121;
    /* font-weight: 500; */
}

a:hover {
    @apply opacity-90
    no-underline;
}

img {
    @apply max-w-full;
}

section {
    @apply md:mb-32
    mb-24;
}

.mainButton {
    @apply bg-white
    text-text
    text-center
    tracking-wide
    px-8
    py-4
    inline-block
    transition-all
    border
    border-white;
}

.mainButton:hover {
    @apply bg-primary
    border
    text-white
    border-primary;
}

.mainHeading {
    @apply bg-primary
    text-white
    px-8
    py-2
    md:text-3xl
    text-xl
    inline-block
    mb-8;
}

.mainCopy {
}

.largeCopy {
}

.gmap {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
}
.gmap iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.pagination {
    @apply text-center;
}

.page-numbers {
    @apply p-4;
}

.bottom_button {
    @apply text-center
    mt-8
    mb-16;
}

.no_thumbnail {
    aspect-ratio: 4 / 3;
    @apply w-full
    transition-transform
    object-cover;
}

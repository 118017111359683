.recruit__container {
    @apply container
    mx-auto
    px-3
    lg:max-w-screen-lg;
}

.recruit__loopWrap {
}

.recruit__item {
    @apply flex
    lg:flex-row
    flex-col
    py-2;
}

.recruit__name {
    min-width: 10rem;
}

.recruit__content {
    @apply lg:px-8;
}

.recruit__description__wrap {
    @apply grid
    md:grid-cols-2
    grid-cols-1
    gap-8
    md:mt-24
    mt-16;
}

.recruit__description {
    @apply p-2;
}

.recruit__description__title {
    @apply md:text-xl
    text-lg
    py-2;
}

.recruit__description__image img {
    aspect-ratio: 1 / 1;
    @apply w-full
    object-cover
    object-top
    pb-2;
}

.recruit__columnTwo {
    @apply grid
    md:grid-cols-2
    grid-cols-1
    lg:gap-16
    gap-4
    md:mb-8
    mb-4
    lg:max-w-screen-lg
    mx-auto;
}

.recruit__bannerWrapper {
    @apply relative
    w-full
    overflow-hidden
    block
    !opacity-100;
}

.recruit__bannerImage {
    @apply w-full
    lg:h-28rem
    md:h-96
    h-64
    object-cover
    transition-transform;
}

.recruit__banner__a:hover .recruit__bannerImage {
    transform: scale(1.1);
}

.recruit__bannerLabel {
    @apply bg-white
    py-8
    px-16;
}

.recruit__bannerLabel__p {
    text-align-last: justify;
    @apply lg:text-3xl
    text-2xl
    font-bold
    lg:leading-loose
    leading-relaxed
    border-b;
}

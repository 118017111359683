.footer__container {
    @apply container
    mx-auto
    px-3
    mb-8;
}

.footer__columnOne {
    @apply grid
    grid-cols-1
    md:mb-8
    mb-4;
}

.footer__columnTwo {
    @apply grid
    md:grid-cols-2
    grid-cols-1
    md:gap-8
    gap-4
    md:mb-8
    mb-4;
}

.footer__bannerWrapper {
    @apply relative
    w-full
    overflow-hidden
    block
    !opacity-100;
}

.footer__bannerImage {
    @apply w-full
    lg:h-80
    md:h-48
    h-36
    object-cover
    transition-transform;
}

.footer__bannerImage:hover {
    transform: scale(1.1);
}

.footer__bannerLabel {
    @apply absolute
    left-0
    bottom-4
    bg-primary
    text-white
    lg:px-16
    px-8
    sm:py-2
    md:text-3xl
    sm:text-2xl
    text-lg
    tracking-wide;
}

.footer__buttonContainer {
    @apply mb-8
    container
    mx-auto
    px-3;
}

.footer__button {
    @apply bg-primary
    text-white
    md:py-8
    py-4
    w-full
    block
    text-center
    md:text-3xl
    sm:text-2xl
    text-lg
    tracking-wide
    transition-all
    border
    border-primary;
}

.footer__button:hover {
    @apply bg-white
    text-primary;
}

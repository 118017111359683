@import "../../node_modules/jquery-drawer/dist/css/drawer.css";

.drawer-nav {
    @apply bg-white;
}

.drawer__wrapper {
    @apply container
    mx-auto
    mt-20
    mb-16;
}

.drawer-hamburger-icon,
.drawer-hamburger-icon:before,
.drawer-hamburger-icon:after {
    height: 3px;
}

.drawer-menu-item {
    @apply text-text
    text-left
    px-0
    hover:no-underline;
}

.drawer-nav {
    @apply px-4
    bg-bgYel;
}

.drawer__logo {
    @apply w-40
    py-6;
}

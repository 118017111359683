.hero__container {
    @apply container
    mx-auto
    px-3;
}

.hero__grid {
    @apply grid
    md:grid-cols-12
    grid-cols-1
    md:mb-4
    mb-6
    md:gap-12
    gap-4;
}

.hero__image__wrapper {
    @apply relative;
}

.hero__entry {
    @apply absolute
    top-4
    -left-4
    transition-transform
    lg:w-36
    md:w-24
    w-16;
}

.hero__entry:hover {
    transform: scale(1.1);
}

.hero__left {
    @apply md:col-span-8
    col-span-1
    /* md:order-1 */
    /* order-2 */
    self-center;
}

.hero__right {
    @apply md:col-span-4
    col-span-1
    lg:mx-2
    /* md:order-2 */
    /* order-1; */;
}

.hero__banner__saron.pc {
    @apply md:block
    hidden;
}

.hero__banner__saron.sp {
    @apply md:hidden;
}

.hero__logo__main {
    @apply mb-4;
}

.hero__logo__sub {
}

.hero-child__logo {
    @apply container
    mx-auto
    px-3
    md:mb-12
    mb-6;
}

.hero-child__logo img {
    @apply w-full;
}

.hero-child__container {
    @apply relative
    md:mb-28
    mb-20;
}

.hero-child__container::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
}

.hero-child__text {
    z-index: 2;
    @apply absolute
    top-0
    md:left-16
    left-4
    text-white
    md:text-4xl
    text-xl
    h-full
    flex
    items-center;
}

.hero-child__image {
    @apply lg:h-36rem
    md:h-96
    h-40
    object-cover
    w-full;
}

.info__columnTwo {
    @apply grid
    md:grid-cols-2
    grid-cols-1
    lg:gap-16
    gap-4
    md:mb-8
    mb-4
    lg:max-w-screen-lg
    mx-auto;
}

.info__bannerWrapper {
    @apply relative
    w-full
    overflow-hidden
    block
    !opacity-100;
}

.info__bannerImage {
    @apply w-full
    lg:h-28rem
    md:h-96
    h-64
    object-cover
    transition-transform;
}

.info__banner__a:hover .info__bannerImage {
    transform: scale(1.1);
}

.info__bannerLabel {
    @apply bg-white
    py-8
    px-16;
}

.info__bannerLabel__p {
    text-align-last: justify;
    @apply lg:text-3xl
    text-2xl
    font-bold
    lg:leading-loose
    leading-relaxed
    border-b;
}

.aboutPage section {
    @apply md:mb-48
    mb-32;
}

.aboutHeading {
    @apply lg:text-3xl
    md:text-2xl
    text-xl
    font-bold
    tracking-wider
    text-center
    md:mb-6
    mb-4;
}

.about__largeText {
    @apply lg:text-2xl
    md:text-xl
    text-lg
    lg:leading-relaxed
    lg:tracking-wide
    leading-relaxed
    tracking-wide
    font-bold
    py-4
    mb-4;
}

.about__omoi {
    @apply container
    mx-auto
    lg:px-12;
}

.about__omoi__bg {
    @apply relative
    overflow-hidden;
}
.about__omoi__bg:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.7);
    display: block;
    position: absolute;
    width: 90%;
    height: 80%;
    z-index: -1;
    right: 0;
    top: 4rem;
}

.about__omoi__image {
    @apply w-full
    lg:h-34rem
    md:h-52
    h-40
    object-cover;
}

.about__omoi__inner {
    @apply grid
    md:grid-cols-4
    grid-cols-1
    md:gap-8
    gap-4;
}

.about__omoi__inner-left {
    @apply col-span-1
    flex
    items-center
    justify-center
    md:border-r-3
    md:mt-0
    mt-8;
}

.about__omoi__inner-right {
    @apply col-span-3
    px-8
    md:py-4;
}

.about__policy__bg {
    @apply relative
    overflow-hidden;
}
.about__policy__bg:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.7);
    display: block;
    position: absolute;
    width: 75%;
    height: 90%;
    z-index: -1;
    right: 0;
    top: 1rem;
}

.about__policy {
    @apply px-3
    md:px-0;
}
.about__policy__container {
    @apply container
    mx-auto
    px-3
    text-center;
}

.about__policy__text {
    @apply lg:text-xl
    md:text-lg
    text-left
    inline-block;
}

.about__policy__text p {
    @apply mb-4;
}

.about__policy__text span {
    @apply inline-block
    py-4;
}

.about__service {
    @apply container
    mx-auto
    lg:px-12
    px-3;
}

.about__service__mainImage {
    @apply lg:h-34rem
    md:h-64
    h-44
    object-cover
    w-full;
}

.about__service__item {
    @apply bg-white
    md:p-8
    p-4
    py-8
    grid
    md:grid-cols-2
    grid-cols-1
    md:gap-8
    mb-12;
}

.about__service__item__wrap {
    @apply mt-16
    lg:max-w-4xl
    mx-auto;
}

.about__service__text {
    @apply text-lg
    whitespace-pre-line
    leading-loose;
}

.about__history {
    @apply container
    mx-auto
    px-3;
}

.about__award {
    @apply container
    mx-auto
    px-3;
}

.about__info__bg {
    @apply relative
    overflow-hidden;
}
.about__info__bg:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.7);
    display: block;
    position: absolute;
    width: 72%;
    height: 92%;
    z-index: -1;
    right: 0;
    top: 1rem;
}

.about__company {
    @apply container
    mx-auto
    px-3
    lg:max-w-4xl;
}

.about__tables {
    @apply w-full;
}
.about__tables tr {
    @apply border-b
    flex
    flex-col
    md:flex-row;
}

.about__tables th {
    min-width: 10rem;
    @apply align-top
    text-left
    md:text-center;
}

.about__tables th {
    @apply md:py-4
    pt-4
    text-left
    pl-4;
}
.about__tables td {
    @apply md:py-4
    pb-4;
}

.about__tables td {
    @apply md:px-8;
}

.about__columns {
    /* max-width: fit-content; */
    @apply mx-auto
    lg:max-w-4xl;
}

.about__columns dl {
    @apply md:flex
    md:mb-0
    mb-8;
}

.about__columns dt {
    min-width: 10rem;
    @apply md:p-2;
}

.about__columns dd {
    @apply py-2;
}
